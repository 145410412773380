
import Vue from "vue";
import { mapGetters } from "vuex";
import { InformationData } from "@/types/interfaces";
import EarningsBreakdownModal from "@/components/Modals/EarningsBreakdownModal.vue";
import BadgesInfoModal from "@/components/Modals/BadgesInfoModal.vue";

export default Vue.extend({
  name: "FinishedMentorcastRating",
  components: {
    EarningsBreakdownModal,
    BadgesInfoModal
  },
  data() {
    return {
      maxRatingValue: 10,
      infoData: ([
        {
          name: "max_seats",
          label: (this as any).$t("rating.ratingInfoDataSeatsAvailable"),
          icon: "seat-block",
          type: "mentorcast"
        },
        {
          name: "seat_price",
          label: (this as any).$t("rating.ratingInfoDataPricePerSeat"),
          icon: "paid",
          type: "mentorcast"
        },
        {
          name: "user_registered",
          label: (this as any).$t("rating.ratingInfoDataUsersRegistered"),
          icon: "user-profile",
          type: "overview"
        },
        {
          name: "total_participated",
          label: (this as any).$t("rating.ratingInfoDataTotalParticipated"),
          icon: "participants",
          type: "overview"
        }
      ] as unknown) as InformationData[]
    };
  },
  computed: {
    ...mapGetters({
      profile: "profile/getProfile",
      ratings: "mentorcast/getMentorcastRatings",
      mentorcast: "mentorcast/getMentorcast",
      overview: "mentorcast/getMentorcastOverview"
    })
  },
  mounted(): void {
    this.$store
      .dispatch("mentorcast/GET_MENTORCAST_OVERVIEW", this.$route.params.id)
      .then(() => {
        this.$store
          .dispatch("mentorcast/GET_MENTORCAST", this.$route.params.id)
          .then(() => {
            this.$store.dispatch(
              "mentorcast/GET_RATINGS",
              this.$route.params.id
            );
          });
      })
      .catch(error => {
        if (error.response.status == 403) {
          this.$router.push(`/mentorcasts/${this.$route.params.id}`);
        }
      });
  },
  methods: {
    getInformation(type: any, name: any) {
      if (type == "mentorcast") {
        return this.mentorcast[name];
      } else if (type == "overview") {
        return this.overview[name];
      }
    }
  }
});
